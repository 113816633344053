var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Periodo","item-text":"nombre","item-value":"id","items":_vm.periodos},model:{value:(_vm.periodoId),callback:function ($$v) {_vm.periodoId=$$v},expression:"periodoId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Grupos","item-text":"nombre","item-value":"id","items":_vm.grupos},model:{value:(_vm.grupoId),callback:function ($$v) {_vm.grupoId=$$v},expression:"grupoId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Asignatura","item-text":"nombre","item-value":"id","items":_vm.asignaturas},model:{value:(_vm.asignaturaId),callback:function ($$v) {_vm.asignaturaId=$$v},expression:"asignaturaId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"primary","disabled":_vm.habilitarBtn}},'v-btn',attrs,false),on),[_vm._v(" Iniciar asistencia ")])]}}])},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Lista de asistencia")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","nudge-right":40,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha del llamdo","prepend-icon":"mdi-calendar","readonly":"","persistent-hint":""},model:{value:(_vm.fechaActual),callback:function ($$v) {_vm.fechaActual=$$v},expression:"fechaActual"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1950-01-01"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.fechaActual),callback:function ($$v) {_vm.fechaActual=$$v},expression:"fechaActual"}})],1)]],2)],1),_c('v-row',[_c('v-btn',{on:{"click":_vm.crearAsistencia}},[_vm._v("crear asistencia")])],1),_c('v-row',[_c('v-col',[_c('v-virtual-scroll',{attrs:{"items":_vm.estudiantesAsistencia,"item-height":50,"height":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nombreCompleto))])],1),_c('v-list-item-action',[_c('v-btn-toggle',{attrs:{"group":""}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.asistio(item)}}},[_c('v-icon',[_vm._v(" mdi-text-box-check-outline ")])],1),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.falto(item)}}},[_c('v-icon',[_vm._v(" mdi-progress-close ")])],1)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }