<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Grupos"
          item-text="nombre"
          item-value="id"
          :items="grupos"
          v-model="grupoId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Asignatura"
          item-text="nombre"
          item-value="id"
          :items="asignaturas"
          v-model="asignaturaId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              color="primary"
              :disabled="habilitarBtn"
              v-bind="attrs"
              v-on="on"
            >
              Iniciar asistencia
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Lista de asistencia</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <template>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-right="40"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaActual"
                            label="Fecha del llamdo"
                            prepend-icon="mdi-calendar"
                            readonly
                            persistent-hint
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="fechaActual"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1950-01-01"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn @click="crearAsistencia">crear asistencia</v-btn>
                </v-row>
                <v-row>
                  <v-col>
                    <v-virtual-scroll
                      :items="estudiantesAsistencia"
                      :item-height="50"
                      height="300"
                    >
                      <template v-slot:default="{ item }">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.nombreCompleto
                            }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn-toggle group>
                              <v-btn small @click="asistio(item)">
                                <v-icon> mdi-text-box-check-outline </v-icon>
                              </v-btn>
                              <v-btn small @click="falto(item)">
                                <v-icon> mdi-progress-close </v-icon>
                              </v-btn>
                            </v-btn-toggle>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    periodoId: 0,
    grupoId: 0,
    asignaturaId: 0,
    asistencia: null,
    periodos: [],
    grupos: [],
    rendimientos: [],
    asignaturas: [],
    menu: false,
    estudiantes: [],
    estudiantesAsistencia: [],
    fechaActual: new Date().toISOString().substr(0, 10),
  }),
  computed: {
    habilitarBtn() {
      let habilitar =
        this.periodoId > 0 && this.grupoId > 0 && this.asignaturaId > 0;
      return !habilitar;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions([
      "GetPeriodos",
      "GetCursosDocente",
      "GetDocentesGrupo",
      "GetDocentesAsignaturas",
      "GetAlumnosGrupoPerido",
      "RegistrarAsistencia",
      "RegistrarFallas",
    ]),
    initialize() {
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    asistio(item) {
      this.remover(item);
    },
    falto(item) {
      let datos = {};
      datos.id = 0;
      datos.estudianteId = item.estudianteId;
      datos.asistenciaId = this.asistencia.id;

      this.RegistrarFallas(datos).then((x) => {
        this.remover(item);
      });
    },
    remover(item) {
      debugger;
      const index = this.estudiantesAsistencia.indexOf(item);
      if (index > -1) {
        this.estudiantesAsistencia.splice(index, 1);
      }
    },
    crearAsistencia() {
      let datos = {};
      datos.Fecha = this.fechaActual;
      datos.NumeroAlumnos = this.estudiantes.length;
      datos.periodoId = this.periodoId;
      datos.grupoId = this.grupoId;
      datos.asignaturaId = this.asignaturaId;
      datos.id = 0;

      this.RegistrarAsistencia(datos).then((x) => {
        this.estudiantesAsistencia = this.estudiantes.slice();
        this.asistencia = x.data;
      });
    },
  },
  watch: {
    periodoId(nuevoValor) {
      this.grupoId = 0;
      this.asignaturaId = 0;
      this.rendimientos = [];
      this.GetDocentesGrupo(nuevoValor).then((x) => (this.grupos = x.data));
    },
    grupoId(nuevoValor) {
      this.asignaturaId = 0;
      if (nuevoValor > 0) {
        let datos = {};
        datos.periodo = this.periodoId;
        datos.grupo = nuevoValor;
        this.GetDocentesAsignaturas(datos).then(
          (x) => (this.asignaturas = x.data)
        );

        this.GetAlumnosGrupoPerido(datos).then(
          (x) => (this.estudiantes = x.data)
        );
      } else {
        this.estudiantes = [];
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
};
</script>