<template>
  <RegistroAsistencia></RegistroAsistencia>
</template>
<script>
import RegistroAsistencia from "@/components/RegistroAsistencia.vue";
export default {
  components: {
    RegistroAsistencia,
  },
};
</script>